.car__item {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #2c3e50; /* Border color */
}

.car__img img {
  border-radius: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding: 4px!important;
}


.car__item:hover {
  border-radius: 10px;

  transform: translateY(0px);
  border: 2px solid transparent; /* Initially set border to transparent */
}

/* Animate border construction */
.car__item:hover::before {

  border-radius: 10px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid  #3498db; /* Border color */
  animation: border-construction 0.8s ease forwards; /* Animation */
}

@keyframes border-construction {
  from {
    height: 0; /* Start with no border */
  }
  to {
    height: 100%; /* Expand border to full width */
    z-index: -1;
  }
}


.car__item-info span i {
  color: #f9a826;
  font-size: 1.1rem;
}

.car__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  background: #000d6b;
  padding: 8px 0px;
}

.car__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.car__item-btn a:hover {
  color: #fff;
}
.car__btn-details {
  background: #f9a826;
  border-radius: 0px 4px 4px 0px;
}

.car__btn-rent {
  border-radius: 4px 0px 0px 4px;
}

.car__item-content h4 {
  font-size: 1.8rem;
}

.car__item-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .car__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .car__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .car__item-info {
    flex-wrap: wrap;
  }

  .car__item-info span {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .car__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .car__item h4 {
    font-size: 1rem;
  }

  .car__item h6 {
    font-size: 0.9rem;
  }

  .car__item-info span {
    font-size: 0.7rem;
  }

  .car__item-info span i {
    font-size: 0.9rem;
  }

  .car__item-btn {
    font-size: 0.8rem;
  }
}

.btn-custom {
  display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 20px !important;
    font-weight: 600 !important;
    background-color: #2c3e50 !important;
    border-radius: 50px !important;
    padding: 12px 60px;
    transition: all .4s ease;
    margin: 30px;
    text-decoration: none;
}

.btn-custom:hover {
  background-color:   #3498db !important;
}