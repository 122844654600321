.gallery-container {
    margin-top: 20px;
  }
  
  .gallery-carousel {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .carousel-control {
    background-color: rgb(0, 0, 0);
  }
  
  .gallery-nav {
    margin-top: 10px;
    align-items: center;
  }
  
  .photo-row {
    margin-top: 20px;
  }
  
  .photo-col {
    margin-bottom: 20px;
  }
  
  .photo-img {
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);


  }
  .photo-img {
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  /* Popup CSS */
  
  .image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .image-popup-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    border-radius: 10px;

  }
  
  .popup-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px!important;

  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 24px;
  }

  .carousel-control-next{
    background-color: rgb(0, 0, 0);

  }

  .carousel-control-prev{
    background-color: rgb(0, 0, 0);

  }
  
  
  