.custom-modal {
    max-width: 400px;
    color: #fff !important;

  }
  .modal-title{
    color: #fff !important;

  }
  
  .modal-header {
    background-color: #2c3e50;;
    color: #fff !important;
  }
  
  .modal-body {
    background-color: #f8f9fa;
  }
  
  .phone-number {
    font-size: 18px;
    color: #333;
  }
  
  .number {
    font-weight: bold;
  }
  
  .modal-footer {
    background-color: #f8f9fa;
  }
  
  
  .close-btn, .call-btn {
    border: none;
    transition: background-color 0.3s;
  }
  
  