.btn-more {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 20px !important;
    font-weight: 600 !important;
    background-color:  #415569 !important;
    border-radius: 10px !important;
    transition: all .4s ease;
  }
  