/* Complete CSS file with new color combination */

:root {
  --primary-color: #2c3e50; /* Dark blue-gray */
  --secondary-color: #3498db; /* Blue */
  --text-color: #ecf0f1; /* Light gray */
}


.phone-link {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.phone-link:hover {
  color: #1abc9c;
}

.phone-link i {
  margin-right: 8px;
  font-size: 20px;
}

.header__top {
  padding: 10px 0px;
  background: var(--primary-color);
  color: var(--text-color);
  animation: slideDown 0.5s ease-in-out;
}

.header__top__left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.header__top__help i {
  background: var(--text-color);
  color: var(--primary-color);
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__location a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 0.9rem;
}

.header__location a:hover {
  background-color: #ecf0f1 !important;
  color:  #161d25 !important;
}

.header__middle {
  /* padding: 20px 0px; */
  background: var(--text-color);
  color: var(--primary-color);
  height: fit-content;
}

.header__location span i {
  font-size: 2.2rem;
  color: var(--primary-color);
}

.header__location i {
  font-size: 2.2rem;
  color: var(--primary-color);
}
.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-color)!important;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: var(--primary-color) !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: var(--text-color);
  justify-content: end;
  font-size: 0.9rem;
}

.header__btn a:hover {
  color: var(--text-color);
}

.main__navbar {
  padding: 15px 0px;
  background: var(--primary-color);
}

.mobile__menu i {
  color: var(--text-color);
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.7rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__item {
  padding: 0;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.2rem;
  font-weight: bold;
 
}

.nav__item:hover {
  color: var(--secondary-color);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.search__box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 7px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: var(--primary-color);
}

.search__box input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--text-color);
  cursor: pointer;
}

.search__box input::placeholder {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

.search__box span i {
  color: rgba(255, 255, 255, 0.555);
  cursor: pointer;
}

.nav__active {
  color: var(--secondary-color);
}

@media only screen and (max-width: 768px) {
  .header__top,
  .header__middle
  .nav__right {
    display: none;
  }

  .mobile__menu {
    display: block;
  }

  .navigation {
    background: rgba(0, 13, 107, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    pointer-events: none;
  }

  .menu__active {
    margin-top: var(--header-height) !important;
    display: block;
    pointer-events: auto;
   }
  
   .menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0!important; /* Add padding for content within the card */
    animation: slideDown 0.5s ease-in-out;
  }
  
  .menu a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.2rem;
    width: 100%;
    transition: background-color 0.3s ease;
    background-color: rgba(255, 255, 255, 0.05); /* Add background color */
    padding: 5px!important; /* Add padding for content within the card */

    margin:0 !important;
  }
  
  
  .menu a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }    
}

.header.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  animation: slideDown 1s ease-in-out;
}

.header.sticky .header__middle {
  display: none;
  animation: slideDown 0.5s ease-in-out;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.static-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.static-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #25D366; /* WhatsApp green */
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.static-icons a i {
  position: absolute; /* Position the icon absolutely within its parent <a> tag */
  font-size: 35px; /* Adjust font size of the icon */
}

.static-icons a.call-icon {
  background-color: #007bff; /* Bootstrap primary color */
}

.static-icons a:hover {
  transform: scale(1.1);
}



.logo img {
  width: 250px; /* Adjust the width as needed */
  height: auto; /* Maintains the aspect ratio */
}

@media (max-width: 576px) {
  .logo img {
    width: 10px; /* Adjust the width for smaller screens */
    height: auto;
  }
}