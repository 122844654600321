.tour__item {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #2c3e50; /* Border color */
  }
  
  .tour__item:hover {
    border-radius: 10px;

    transform: translateY(0px);
    border: 2px solid transparent; /* Initially set border to transparent */
  }
  
  /* Animate border construction */
  .tour__item:hover::before {
    border-radius: 10px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid  #3498db; /* Border color */
    animation: border-construction 0.8s ease forwards; /* Animation */
  }
  
  @keyframes border-construction {
    from {
      height: 0; /* Start with no border */
    }
    to {
      height: 100%; /* Expand border to full width */
      z-index: -1;
    }
  }
  
  .tour__img img {
    border-radius: 10px;
    border-bottom: 1px solid #f0f0f0;
    padding: 4px!important;
  }
  
  .section__title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }

  .car-list-container {
    padding: 0 15px;
  }
  
  .car-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .car-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .car-item:last-child {
    border-bottom: none;
  }
  
  
  .car-icon {
    font-size: 24px;
    color: #ff9f1c;
    margin-right: 10px;
  }
  
  .car-info {
    font-size: 1rem;
    color: #555;
  }
  
  .car-info span {
    color: #ff4d4d; /* Color for the car prices */
    font-weight: bold;
  }

  .btn-custom {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 20px !important;
    font-weight: 600 !important;
    background-color: #2c3e50 !important;
    border-radius: 50px !important;
    padding: 12px 60px;
    transition: all .4s ease;
    margin: 30px;
    text-decoration: none;

  }
  
  .btn-custom:hover {
    background-color:   #3498db !important;
  }