
.map-container {
  position: relative;
  width: 90%;
  padding-bottom: 56.25%; 
  height: 0;
}
.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
/* 
 .info-card {
  position: absolute;
  top: 20%;
 right: 5%; 
  transform: translateY(-50%); 
  background-color: black; 
  color: white;       
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-width: 300px;
}

.info-card .info-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.info-card .info-address {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}

.info-card .view-link {
  font-weight: 400;
}

.info-card .view-link a {
  color: #1e90ff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .info-card {
    right: 2%;
    padding: 10px;
  }

  .info-card .info-title {
    font-size: 14px;
  }

  .info-card .info-address {
    font-size: 12px;
  }
} */
